/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import constants from "../data/constants";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: "standard_fonts/",
};

function EditEntryModal(props) {
  const [file, setFile] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [showDocArticle, setShowDocArticle] = useState(false);
  const editUserButton = useRef(null);

  const verifyFileSizeIsOK = (file) => {
    if (file.size > 20000000) {
      alert("File is too big! Maximum file upload size is 20MB.");
      document.getElementById("edit-pdfFile").value = "";
      setFile("");
    }
  };

  const onFileChange = (event) => {
    setFile(event.target.files[0]);
    verifyFileSizeIsOK(event.target.files[0]);
  };

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  };

  useEffect(() => {
    if (props.openEditEntryModal) {
      document.getElementById("editEntryModal").showModal();
      // Set aria-busy to false for the button and enable it
      editUserButton.current.setAttribute("aria-busy", false);
      editUserButton.current.disabled = false;
      if (props.editEntryModalData["s3_key"]) {
        setShowDocArticle(true);
      }
    } else {
      // Clear the file upload
      document.getElementById("edit-pdfFile").value = "";
      setFile("");
      document.getElementById("editEntryModal").close();
      setShowDocArticle(false);
    }
  }, [props.openEditEntryModal]);

  const handleEditUser = async (e) => {
    e.preventDefault();
    // Set aria-busy to true for the button and disable it
    editUserButton.current.setAttribute("aria-busy", true);
    editUserButton.current.disabled = true;
    // Send request to get presigned POST URL
    const response = await fetch(`${constants.API_URL}/api/db/presigned_url`, {
      method: "GET",
      credentials: "include",
    });
    const data = await response.json();
    // POST file to presigned URL
    const uploadFormData = new FormData();
    Object.keys(data.fields).forEach((key) => {
      uploadFormData.append(key, data.fields[key]);
    });
    uploadFormData.append("file", file);
    try {
      if (file) {
        const uploadResponse = await fetch(data.url, {
          method: "POST",
          body: uploadFormData,
        });
      }
    } catch (error) {
      console.log(error);
    }
    try {
      const formData = new FormData();
      if (file) {
        formData.append("pdfExists", true);
      } else {
        formData.append("pdfExists", false);
      }
      const values = {};
      props.columns.forEach((column) => {
        // Get the value of the input field with the same id as the column name prefixed by "edit-" and replace spaces in the column name with dashes. If the value is null then set the value to an empty string.
        if (
          document.getElementById(`edit-${column.replace(/ /g, "-")}`).value
        ) {
          values[column] = document.getElementById(
            `edit-${column.replace(/ /g, "-")}`
          ).value;
        } else {
          values[column] = "";
        }
      });
      formData.append("values", JSON.stringify(values));

      const response = await fetch(`${constants.API_URL}/api/db/edit`, {
        method: "POST",
        body: formData,
        credentials: "include",
      });
      const data = await response.json();
      if (data.error) {
        console.log(data.error);
      } else {
        props.toggleEditEntryModal();
        // Remove all children of #search-results.
        const searchResults = document.getElementById("search-results");
        while (searchResults.firstChild) {
          searchResults.removeChild(searchResults.firstChild);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowDoc = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("s3_key", props.editEntryModalData["s3_key"]);
      const response = await fetch(`${constants.API_URL}/api/db/getpdf`, {
        method: "POST",
        body: formData,
        credentials: "include",
      });
      const presignedUrl = await response.json(); // JSON is {"url": "https://..."}
      const fileBlob = await fetch(presignedUrl.url).then((r) => r.blob());
      setFile(fileBlob);
      setShowDocArticle(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <dialog id="editEntryModal">
      <article align="left">
        <header>
          <a
            id="closeEditEntryModal"
            aria-label="Close"
            className="close"
            onClick={() => props.toggleEditEntryModal()}
          ></a>
          <hgroup>
            <h1>Edit this entry.</h1>
            <h2>
              You can edit this entry by changing the values in the textboxes
              below and clicking the "Update" button when done.
            </h2>
          </hgroup>
        </header>
        <div>
          <form id="editEntryForm">
            <input
              type="file"
              id="edit-pdfFile"
              name="edit-pdfFile"
              onChange={onFileChange}
              accept="application/pdf"
            />
            {props.columns.map((column) => (
              <>
                {column !== "s3_key" && column !== "ID" ? (
                  <div key={column}>
                    <label htmlFor={column}>{column}</label>
                    {/* column might have spaces so replace with dashes for id */}
                    <input
                      type={column === "Form Date" ? "date" : "text"}
                      id={`edit-${column.replace(/\s/g, "-")}`}
                      defaultValue={
                        props.editEntryModalData
                          ? props.editEntryModalData[column]
                          : ""
                      }
                    ></input>
                  </div>
                ) : (
                  <input
                    type="hidden"
                    id={`edit-${column.replace(/\s/g, "-")}`}
                    defaultValue={
                      props.editEntryModalData
                        ? props.editEntryModalData[column]
                        : ""
                    }
                  ></input>
                )}
              </>
            ))}
            <button
              ref={editUserButton}
              type="submit"
              id="editEntryButton"
              onClick={handleEditUser}
            >
              Update
            </button>
          </form>
        </div>
      </article>
      {showDocArticle && (
        <article id="showDocArticle">
          <button type="submit" id="showDocButton" onClick={handleShowDoc}>
            Show Document
          </button>
        </article>
      )}
      {/* Get PDF from file upload input #pdfFile */}
      {file !== "" && (
        <article>
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </article>
      )}
    </dialog>
  );
}

export default EditEntryModal;
