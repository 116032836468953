import constants from "./data/constants";

class Auth {
  constructor() {
    this.authenticated = false;
  }

  login(cb) {
    this.authenticated = true;
    cb && cb();
    return this.authenticated;
  }

  logout(cb) {
    this.authenticated = false;
    cb && cb();
    return this.authenticated;
  }

  async isAuthenticated() {
    console.log("Running isAuthenticated()...");
    const response = await fetch(`${constants.API_URL}/api/token/validate`, {
      method: "POST",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "valid") {
          return this.login();
        } else {
          return this.logout();
        }
      });
    const value = await response;
    return value;
  }
}

export default new Auth();
