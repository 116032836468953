import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import constants from "../data/constants";
import auth from "../auth";
import "./LoginPage.css";

const LoginPage = (props) => {
  const formRef = useRef(null);
  const buttonRef = useRef(null);
  const [loginForm, setLoginForm] = useState(null);
  const [loginButton, setLoginButton] = useState(null);
  const [loginError, setLoginError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setLoginForm(formRef.current);
    setLoginButton(buttonRef.current);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Set aria-busy to true on the button
    loginButton.setAttribute("aria-busy", true);
    // Get form data from loginForm
    fetch(`${constants.API_URL}/api/token/get`, {
      method: "POST",
      body: new FormData(loginForm),
      credentials: "include",
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.status);
        }
        auth.login(() => {
          navigate("/search");
        });
      })
      .catch((err) => {
        setLoginError("Invalid username or password");
      })
      .finally(() => {
        loginButton.removeAttribute("aria-busy");
      });
  };

  return (
    <div>
      <main className="container">
        <article className="grid" style={{ display: "grid" }}>
          <article style={{ padding: "2.5rem" }}>
            <hgroup>
              <h1>JSMP Records Login</h1>
              <h2>Enter details below to access JSMP records.</h2>
            </hgroup>
            <form ref={formRef} onSubmit={handleSubmit}>
              <input
                type="text"
                name="username"
                placeholder="Username"
                aria-label="username"
                autoComplete="nickname"
                required
              />
              <input
                type="password"
                name="password"
                placeholder="Password"
                aria-label="Password"
                autoComplete="current-password"
                required
              />
              <button ref={buttonRef} type="submit">
                Login
              </button>
            </form>
            {loginError && (
              <p style={{ backgroundColor: "#FF0000" }}>{loginError}</p>
            )}
          </article>
          <article
            id="login-image"
            style={{
              backgroundImage: `url("/login-image.jpg")`,
              backgroundSize: "cover",
            }}
          ></article>
        </article>
      </main>

      <footer className="container-fluid" style={{ marginTop: "1rem" }}>
        <small>
          Contact <a href="https://mueezkhan.com">Mueez</a> for any issues.
        </small>
      </footer>
    </div>
  );
};

export default LoginPage;
