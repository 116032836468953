import React, { useEffect, useRef, useState } from "react";
import constants from "../data/constants";

const SearchModal = (props) => {
  const searchButtonRef = useRef(null);
  const searchTypeRef = useRef(null);
  const searchTermRef = useRef(null);
  const [searchType, setSearchType] = useState("Deceased");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setSearchType(searchTypeRef.current.value);
    setSearchTerm(searchTermRef.current.value);
  }, []);

  const handleSearch = async (e) => {
    e.preventDefault();
    // Set the search button aria-busy attribute to true.
    searchButtonRef.current.setAttribute("aria-busy", true);
    const searchTypeValue = searchTypeRef.current.value;
    const searchTermValue = searchTermRef.current.value;
    try {
      const response = await fetch(
        `${constants.API_URL}/api/db/search?searchType=${searchTypeValue}&searchTerm=${searchTermValue}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      const data = await response.json();
      // Remove existing search result <details> elements
      const searchResults = document.getElementById("search-results");
      while (searchResults.firstChild) {
        searchResults.removeChild(searchResults.firstChild);
      }
      // Get number of search results and display below <main> as a new <h2>
      // If #search-results-count already exists, remove it
      const existingSearchResultsCount = document.getElementById(
        "search-results-count"
      );
      if (existingSearchResultsCount) {
        existingSearchResultsCount.remove();
      }
      const searchResultsCount = document.createElement("h2");
      searchResultsCount.id = "search-results-count";
      searchResultsCount.style.margin = "3rem 0 0 0";
      searchResultsCount.textContent = `${data.length} search results found.`;
      // If 1 search result, change text to singular
      if (data.length === 1) {
        searchResultsCount.textContent = `${data.length} search result found.`;
      }
      const main = document.querySelector("main");
      main.appendChild(searchResultsCount);
      // Create new <details> elements for each search result with details in bullet points within an <article> element and append to #search-results
      data.forEach((result) => {
        const article = document.createElement("article");
        const details = document.createElement("details");
        const summary = document.createElement("summary");
        const ul = document.createElement("ul");
        summary.textContent = `${result.Deceased}`;
        Object.keys(result).forEach((key) => {
          // if (
          //   key === "Documents Link" &&
          //   result[key] !== "" &&
          //   result[key] !== null
          // ) {
          //   const a = document.createElement("a");
          //   a.href = result[key];
          //   a.textContent = result[key];
          //   a.target = "_blank";
          //   li.appendChild(document.createTextNode(`${key}: `));
          //   li.appendChild(a);
          if (key !== "s3_key" && key !== "ID") {
            const li = document.createElement("li");
            li.textContent = `${key}: ${result[key]}`;
            ul.appendChild(li);
          }
        });
        // Add edit button to each <details> element
        const editButton = document.createElement("button");
        editButton.textContent = "Edit";
        editButton.addEventListener("click", () => {
          props.toggleEditEntryModal(result);
        });
        details.appendChild(summary);
        details.appendChild(ul);
        details.appendChild(editButton);
        article.appendChild(details);
        searchResults.appendChild(article);
      });
    } catch (error) {
      console.log(error);
    }
    // Remove the search button aria-busy attribute.
    searchButtonRef.current.removeAttribute("aria-busy");
  };

  return (
    <>
      <main className="container">
        <article className="grid" style={{ margin: "auto" }}>
          <hgroup>
            <h1>JSMP Records</h1>
            <h2>
              Select a column to search from and enter your search term, then
              click the Search button.
            </h2>
          </hgroup>
          <form id="searchForm">
            <label htmlFor="searchType">Column to search from</label>
            <select
              ref={searchTypeRef}
              id="searchType"
              name="searchType"
              onChange={(e) => setSearchType(e.target.value)}
            >
              {props.columns.map((type, index) =>
                type === "Deceased" ? (
                  <option key={index} value={type} selected>
                    {type}
                  </option>
                ) : type === "ID" || type === "s3_key" ? (
                  <></>
                ) : (
                  <option key={index} value={type}>
                    {type}
                  </option>
                )
              )}
            </select>
            <label htmlFor="searchTerm">Search Term</label>
            {searchType !== "Form Date" ? (
              <input
                ref={searchTermRef}
                type="search"
                id="searchTerm"
                name="searchTerm"
              />
            ) : (
              <input
                ref={searchTermRef}
                type="date"
                id="searchTerm"
                name="searchTerm"
              />
            )}
            <button
              ref={searchButtonRef}
              type="button"
              className="btn btn-primary"
              onClick={handleSearch}
            >
              Search
            </button>
            <button
              id="addUserButton"
              className="secondary"
              onClick={(e) => {
                props.toggleAddUserModal();
                e.preventDefault();
              }}
            >
              Add an entry
            </button>
          </form>
        </article>
      </main>
      <section id="search-results" className="container" align="left"></section>
    </>
  );
};

export default SearchModal;
