import React, { useEffect, useState } from "react";
import StatBoxes from "../components/StatBoxes";
import SearchModal from "../components/SearchModal";
import AddUserModal from "../components/AddUserModal";
import EditEntryModal from "../components/EditEntryModal";
import constants from "../data/constants";

const SearchPage = (props) => {
  const [columns, setColumns] = useState([]);
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [openEditEntryModal, setOpenEditEntryModal] = useState(false);
  const [editEntryModalData, setEditEntryModalData] = useState({});
  useEffect(() => {
    const getColumns = async () => {
      const response = await fetch(`${constants.API_URL}/api/db/columns`, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      setColumns(data);
    };
    getColumns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const toggleAddUserModal = () => {
    setOpenAddUserModal(!openAddUserModal);
  };
  const toggleEditEntryModal = (data) => {
    setEditEntryModalData(data);
    setOpenEditEntryModal(!openEditEntryModal);
  };
  return (
    <>
      <StatBoxes />
      <SearchModal
        toggleAddUserModal={toggleAddUserModal}
        toggleEditEntryModal={toggleEditEntryModal}
        columns={columns}
      />
      <AddUserModal
        toggleAddUserModal={toggleAddUserModal}
        openAddUserModal={openAddUserModal}
        columns={columns}
      />
      <EditEntryModal
        toggleEditEntryModal={toggleEditEntryModal}
        openEditEntryModal={openEditEntryModal}
        columns={columns}
        editEntryModalData={editEntryModalData}
      />
    </>
  );
};

export default SearchPage;
