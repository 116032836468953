import React, { useState, useEffect } from "react";

function Nav() {
  // If user's preferred color scheme is dark then set the switch with id themeSwitch to checked.
  useEffect(() => {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      document.getElementById("themeSwitch").checked = true;
    }
  }, []);
  // Set html tag's data-theme to dark or light by clicking the switch
  const [theme, setTheme] = useState("light");
  // If user color scheme preference is dark then setTheme to dark
  useEffect(() => {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      setTheme("dark");
    }
  }, []);
  // Toggle theme function for when user clicks switch button
  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
      document.documentElement.setAttribute("data-theme", "dark");
    } else {
      setTheme("light");
      document.documentElement.setAttribute("data-theme", "light");
    }
  };

  return (
    <nav>
      <ul></ul>
      <ul>
        <li>
          Theme switch{"    "}
          <input
            id="themeSwitch"
            type="checkbox"
            name="switch"
            role="switch"
            onClick={toggleTheme}
          />
        </li>
      </ul>
    </nav>
  );
}

export default Nav;
