import React from "react";
import { useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import auth from "./auth";

const ProtectedRoutes = (props) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const checkAuthenticationStatus = async () => {
      const value = await auth.isAuthenticated();
      setIsAuthenticated(value);
      setIsLoading(false);
    };

    checkAuthenticationStatus();
  }, []);

  if (isLoading) {
    return (
      <main className="container">
        <article aria-busy="true">Loading...</article>
      </main>
    );
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
