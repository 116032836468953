import React, { useEffect, useState } from "react";
import constants from "../data/constants";
import "./StatBoxes.css";

function StatBoxes(props) {
  const [stats, setStats] = useState({});

  // Get stats from backend
  useEffect(() => {
    const getStats = async () => {
      const response = await fetch(`${constants.API_URL}/api/db/stats`, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      setStats(data);
    };
    getStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* Square boxes with a giant number for each and text below each */}
      <div className="grid">
        <article>
          <hgroup style={{ padding: 0, margin: 0 }}>
            <h2>{stats["total_records"]}</h2>
            <h3>Total Records</h3>
          </hgroup>
        </article>
        <article>
          <hgroup style={{ padding: 0, margin: 0 }}>
            <h2>{stats["records_need_review"]}</h2>
            <h3>Records Need Review</h3>
          </hgroup>
        </article>
      </div>
    </>
  );
}

export default StatBoxes;
