/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import constants from "../data/constants";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: "standard_fonts/",
};

function AddUserModal(props) {
  const [file, setFile] = useState("");
  const [numPages, setNumPages] = useState(null);
  const addUserButton = useRef(null);

  const verifyFileSizeIsOK = (file) => {
    if (file.size > 20000000) {
      alert("File is too big! Maximum file upload size is 20MB.");
      document.getElementById("pdfFile").value = "";
      setFile("");
    }
  };

  const onFileChange = (event) => {
    setFile(event.target.files[0]);
    verifyFileSizeIsOK(event.target.files[0]);
  };

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  };

  useEffect(() => {
    if (props.openAddUserModal) {
      document.getElementById("addUserModal").showModal();
      // Set aria-busy to false for the button and enable it
      addUserButton.current.setAttribute("aria-busy", false);
      addUserButton.current.disabled = false;
    } else {
      // Clear the file upload
      document.getElementById("pdfFile").value = "";
      setFile("");
      // Clear the form
      props.columns.forEach((column) => {
        if (document.getElementById(column)) {
          document.getElementById(column).value = "";
        }
      });
      // Close the modal
      document.getElementById("addUserModal").close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openAddUserModal]);

  const handleAddUser = async (e) => {
    e.preventDefault();
    // Set aria-busy to true for the button and disable it
    addUserButton.current.setAttribute("aria-busy", true);
    addUserButton.current.disabled = true;
    // Send request to get presigned POST URL
    const response = await fetch(`${constants.API_URL}/api/db/presigned_url`, {
      method: "GET",
      credentials: "include",
    });
    const data = await response.json();
    // POST file to presigned URL
    const uploadFormData = new FormData();
    Object.keys(data.fields).forEach((key) => {
      uploadFormData.append(key, data.fields[key]);
    });
    uploadFormData.append("file", file);
    try {
      if (file) {
        const uploadResponse = await fetch(data.url, {
          method: "POST",
          body: uploadFormData,
        });
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const formData = new FormData();
      if (file) {
        formData.append("pdfExists", true);
      } else {
        formData.append("pdfExists", false);
      }
      const values = {};
      props.columns.forEach((column) => {
        if (document.getElementById(column)) {
          values[column] = document.getElementById(column).value;
        }
      });
      formData.append("values", JSON.stringify(values));
      const response = await fetch(`${constants.API_URL}/api/db/add`, {
        method: "POST",
        body: formData,
        credentials: "include",
      });
      const data = await response.json();
      if (data.error) {
        console.log(data.error);
      } else {
        props.toggleAddUserModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <dialog id="addUserModal" className="grid">
      <article align="left">
        <header>
          <a
            id="closeAddUserModal"
            href="#"
            aria-label="Close"
            className="close"
            onClick={() => props.toggleAddUserModal()}
          ></a>
          <hgroup>
            <h1>Add an entry.</h1>
            <h2>
              You can add a database entry by filling in the form below and
              clicking the "Add Entry" button. Try to use the example format for
              each textbox if available, otherwise type as you see fit.
            </h2>
          </hgroup>
        </header>
        <div>
          <form id="addUserForm">
            <input
              type="file"
              id="pdfFile"
              name="pdfFile"
              onChange={onFileChange}
              accept="application/pdf"
            />
            {props.columns.map((column, index) => (
              <>
                {column !== "s3_key" && column !== "ID" ? (
                  <div key={index}>
                    <label htmlFor={column}>{column}</label>
                    <input
                      type={column === "Form Date" ? "date" : "text"}
                      id={column}
                      name={column}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </>
            ))}
            <button
              id="addUserButton"
              ref={addUserButton}
              className="secondary"
              onClick={handleAddUser}
              type="submit"
            >
              Add Entry
            </button>
          </form>
        </div>
      </article>
      {/* Get PDF from file upload input #pdfFile */}
      {file !== "" && (
        <article>
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </article>
      )}
    </dialog>
  );
}

export default AddUserModal;
