import "./App.css";
import Nav from "./components/Nav";
import SearchPage from "./pages/SearchPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import ProtectedRoutes from "./ProtectedRoutes";

function App() {
  return (
    <div className="App">
      <Nav />
      <Router>
        <Routes>
          <Route exact path="/" element={<LoginPage />} />
          <Route path="/search" element={<ProtectedRoutes />}>
            <Route
              exact
              path="/search"
              element={<SearchPage />}
              key={document.location.href}
            />
          </Route>
          <Route path="*" element={<LoginPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
